import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Closure from "../components/Closure";
import { HTMLContent } from "../components/Content";

export const IndexPageTemplate = () => {
  return (
    <div>
      <h1 className="is-size-2 is-hidden-tablet full-width-logo page-title-font">
        Lonely Lentil
      </h1>
      <section className="is-hidden-touch">
        <div
          className="full-width-image-container margin-top-0 is-hidden-touch'"
          style={{
            backgroundImage: `url('/img/page-backgrounds/indexbg2020.jpg')`,
          }}
        >
          <h1
            className="is-hidden-touch page-title-font highlight"
            style={{
              padding: "4rem",
              color: "black",
            }}
          >
            Lonely Lentil
          </h1>
        </div>
      </section>
      <section>
        <Closure>
          Please be aware that Lonely Lentil will be closed from Tuesday 28th
          May to Monday 17th June 2024. We’re off on a holiday!
        </Closure>
      </section>

      <section>
        <div className="container">
          <div className="columns">
            <div className="column ">
              <div className="section">
                <div className="tile is-ancestor">
                  <div className="tile is-parent is-vertical">
                    <div className="tile has-text-centered is-child box">
                      <p className="title tile-title">
                        1. Check if we serve to your area
                      </p>
                      <a
                        href="/delivery"
                        className="button is-light is-large title tile-title is-info"
                      >
                        Delivery Information
                      </a>
                      <br />
                    </div>
                  </div>
                  <div className="tile  is-parent">
                    <div className="tile has-text-centered is-child box">
                      <p className="title tile-title">2. Browse our products</p>
                      <a
                        href="/products"
                        className="button is-light is-large title tile-title is-info"
                      >
                        Products
                      </a>
                      <br />
                    </div>
                  </div>
                  <div className="tile  is-parent">
                    <div className="tile has-text-centered is-child box">
                      <p className="title tile-title">
                        3. Get in touch to order!
                      </p>
                      <span>
                        <a
                          className="button is-light is-large title tile-title is-info"
                          href="https://m.me/lonelylentil"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Facebook
                        </a>
                        &nbsp;
                        <a
                          className="button is-light is-large title tile-title is-info"
                          href="mailto:info@lonelylentil.co.uk?Subject=Hello!"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Email
                        </a>
                      </span>
                      <br />
                    </div>
                  </div>
                </div>
                <div className="tile is-ancestor">
                  <div className="tile is-parent is-vertical is-7">
                    <div className="tile  is-child box">
                      <p className="title has-text-centered tile-title">
                        What we stand for
                      </p>
                      <p>
                        Fill your store cupboards with a wide variety of
                        nutritious staples from our dry foods pantry.{" "}
                      </p>
                      <p>
                        Buy only the quantities you need to save money and
                        reduce waste.
                      </p>
                      <p>
                        Dramatically reduce your consumption of single-use
                        plastics.
                      </p>
                      <br />
                      <p>
                        Rice, pasta, pulses, nuts, dried fruits, dried herbs and
                        spices are just a few of our offerings. Our products are
                        organic wherever possible, so you can look after your
                        body and the planet! We are proud to deliver only the
                        highest quality and most delicious products conveniently
                        to your doorstep.
                      </p>
                      <br />
                      <p>
                        Choosing organic is an easy way to avoid pesticides,
                        herbicides and many additives and preservatives. Organic
                        food always comes from trusted sources and has many
                        benefits for the environment and wildlife. We are proud
                        to supply produce certified as organic. This means you
                        can be sure that the food has been produced to the
                        highest standards
                      </p>
                    </div>
                  </div>

                  <div className="tile is-parent is-vertical is-5">
                    <div className="tile has-text-centered is-child box">
                      <p className="title tile-title">Facebook</p>
                      <a
                        href="https://www.facebook.com/lonelylentil"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={"/img/facebook_logo.png"}
                          alt="facebooklogo"
                          style={{ width: "15%" }}
                        />
                      </a>
                    </div>
                    <div className="tile has-text-centered is-child box">
                      <span>
                        <p className="title tile-title">
                          Follow us on Instagram!
                        </p>
                        <a
                          href="https://www.instagram.com/lonelylentil"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={"/img/instagram.png"}
                            alt="instagramlogo"
                            style={{ width: "15%" }}
                          />
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="has-text-centered">
          <a
            href="https://ratings.food.gov.uk/business/1159327"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={"/img/food_hygiene.png"}
              alt="hygienebadge"
              style={{ maxWidth: "250px", paddingBottom: "30px" }}
            />
          </a>
        </div>
      </section>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

// <FacebookProvider appId="303556187216980">
//    <Page href="https://www.facebook.com" tabs="timeline" />
//  </FacebookProvider>

const IndexPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <IndexPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default IndexPage;

export const IndexPageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;

//<PageContent className="content" content={content} />
